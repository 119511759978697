<template>
    <div class="card mt-2"
        :style="{ 'padding': '50px 40px', 'width': '290px', 'height': '509px', 'background-image': 'url(' + img + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'margin': '0 5px', 'border': 'none' }">
        <b class="nunito" :style="{ 'font-size': '32px', 'padding': '0 30px 0 0' }">
            {{ title }}
        </b>
        <br>
        <p class="nunito" :style="{ 'font-size': '24px' }">
            {{ content }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'card-1',

    props: {
        img: String,
        title: String,
        content: String
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            var card = this.$el;
            if (card.getBoundingClientRect().top < window.innerHeight) {
                card.classList.add('show-up')
            }
        }
    }
}
</script>