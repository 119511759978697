<template>
    <div class="section-5"
        :style="{ 'background-image': 'url(' + bg + ')', 'background-position': 'center bottom -100px', 'background-size': 'contain', 'background-repeat': 'no-repeat' }">
        <div class="container-fluid mt-4">
            <div :class="[isSmall() ? {} : 'row d-flex']">
                <Title :style="[isSmall() ? {'position': 'relative', 'margin-left': '10px', 'margin-bottom': '-60px'} : {'position': 'relative', 'margin-left': '-500px', 'margin-bottom': '-60px'}]" class="mt-3" title="REPOTA 2023" :backgroundTitle="img1" fontWeightTitle="bold"/>
                <div class="col-lg-6 col-sm-12"
                    :style="[isSmall() ? {'display': 'flex', 'background-color': '#C34B27', 'margin-bottom': '20px', 'margin-top': '50px', 'justify-content': 'center' } : {'display': 'flex', 'background-color': '#C34B27', 'width': '50%', 'margin-top': '100px', 'justify-content': 'center' }]">
                    <div>
                        <div ref="pdfViewerContainer" class="pdf-viewer-container">
                            <canvas ref="pdfViewerCanvas" class="pdf-viewer-canvas"></canvas>
                        </div>
                        <div class="controls">
                            <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
                            <span style="color:white">{{ currentPage }} / {{ totalPages }}</span>
                            <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12" >
                    <p class="fw-bold" :style="{ 'margin-top': '100px','font-size': '20px' }">
                        <b>
                            Repota là báo cáo thường niên thực hiện bởi Adsota Agency nhằm cung cấp <br> những thông tin thiết thực về
                            bối
                            cảnh
                            thị trường, dự đoán xu hướng mới và <br> những góc nhìn mang tính chuyên môn, có chiều sâu của các chuyên
                            gia.
                        </b>
                    </p>
                    <p class="fw-bold" :style="{ 'font-size': '20px', 'margin-top': '50px'}">
                        Repota 2023 là tài liệu chuyên môn giá trị giúp Marketer, những người quản lý, <br> chủ doanh
                        nghiệp...cập
                        nhật những bước chuyển mình của thị trường, đồng thời <br> đánh giá, thay đổi hướng đi để tối ưu
                        chiến
                        lược
                        tiếp thị và phát triển doanh <br> nghiệp một cách toàn diện.
                    </p>
                    <div :style="[isSmall() ? {'margin-top': '40px'} : {'margin-top': '100px'}]">
                        <p class="fw-bold" :style="{ 'font-size': '36px', 'color': '#065F66', 'margin-bottom': '5px' }">
                            Điểm nổi bật trong Repota 2023?
                        </p>
                        <ul :style="{ 'font-size': '20px', 'padding-left': '20px' }">
                            <li class="py-2">
                                <b>Phần 1:</b> Những chuyển biến của thị trường trong năm 2022 và nửa đầu 2023
                            </li>
                            <li class="py-2">
                                <b>Phần 2:</b> Dự đoán xu hướng những năm tới
                            </li>
                            <li class="py-2">
                                <b>Phần 3:</b> Marketing Chữa Lành
                            </li>
                            <li class="py-2">
                                <b>Phần 4:</b> Case studies và chia sẻ của chuyên gia
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="container" :style="{ 'margin-top': '200px' }">
            <div class="row">
                <Title title="ĐĂNG KÝ THAM DỰ SỰ KIỆN" subTitle="VÀ NHẬN BÁO CÁO REPOTA 2023" :backgroundTitle="img1"
                    fontSizeTitle="36px" fontSizeSubTitle="36px" colorSubTitle="#065F66" fontWeightSubTitle="bold" />
            </div>
            <div id="register" class="row form" :style="{ 'color': '#065F66', 'font-size': '20px' }">
                <form name="submit-to-google-sheet">
                    <div class="col-sm-12 d-flex justify-content-center">
                        <div :style="{ 'width': '100%', 'max-width': '732px' }">
                            <label for="">Họ và tên*</label>
                            <br>
                            <input name="Name" style="width: 100%" type="name">
                            <br>
                            <label for="">Số điện thoại*</label>
                            <br>
                            <input name="Phone" style="width: 100%" type="tel">
                            <br>
                            <label for="">Email*</label>
                            <br>
                            <input name="Email" style="width: 100%" type="email">
                            <br>
                            <label for="">Đơn vị công tác*</label>
                            <br>
                            <input name="Organization" style="width: 100%" type="text">
                            <br>
                            <label for="">Chức vụ*</label>
                            <br>
                            <input name="Title" style="width: 100%" type="text">
                            <br>
                            <label for=""
                                :style="{ 'color': 'black', 'font-style': 'italic', 'font-size': '14px', 'font-weight': 'normal' }">Mọi
                                thông
                                tin cung cấp sẽ được xử lý một cách bảo mật và an
                                toàn</label>
                            <br>
                            <button type="submit" :onClick="Show"
                                :style="{ 'margin-top': '10px', 'margin-bottom': '20px', 'font-size': '24px', 'width': '100%', 'color': 'white', 'background-color': '#065F66', 'border-radius': '8px', 'border': 'none', 'height': '58px' }">
                                Đăng Ký Tham Dự
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
</template>

<script>
//import pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';
import 'pdfjs-dist/web/pdf_viewer.css';
import Title from './Title.vue'
import { toRaw } from 'vue';


export default {
    name: "section-5",
    components: { Title },
    data() {
        return {
            pdf: null,
            currentPage: 1,
            totalPages: 0,
            bg: require("@/images/section5/bg.png"),
            img1: require("@/images/section5/1.png")
        }
    },
    methods: {
        isSmall() {
            if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        Show() {
            document.querySelector('.popup').classList.remove('d-none')
        },
        readDemo() {
            window.open('https://drive.google.com/file/d/1xRsAXIZs_fku-xMj2KdQSlWne_tAApYf/view')
        },
        renderPage(container, canvas, pageNumber) {
            toRaw(this.pdf).getPage(pageNumber).then((page) => {
                const scale = this.isMobile() ? 0.26 : 0.4;
                const viewport = page.getViewport({ scale });

                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport
                };
                page.render(renderContext);
            });
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.renderPage(
                    this.$refs.pdfViewerContainer,
                    this.$refs.pdfViewerCanvas,
                    this.currentPage
                );
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.renderPage(
                    this.$refs.pdfViewerContainer,
                    this.$refs.pdfViewerCanvas,
                    this.currentPage
                );
            }
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'submit.js')
        document.head.appendChild(recaptchaScript)

        const container = this.$refs.pdfViewerContainer;
        const canvas = this.$refs.pdfViewerCanvas;
        const url = '/repota2023.pdf';

        import("pdfjs-dist").then((pdfjsLib)=> {
            pdfjsLib.getDocument(url).promise.then((pdf) => {
                this.pdf = pdf;
                this.totalPages = pdf.numPages;
                this.renderPage(container, canvas, this.currentPage);
        });
    })  
  }
}
</script>

<style>
.section-5 .form {
    padding-bottom: 200px;
}

.section-5 .form input {
    width: 100%;
    max-width: 732px;
    height: 45px;
    border-radius: 8px;
    border-color: #065F66;
    background-color: #F2F9FB;
    border-width: 1px;
    margin-bottom: 15px;
}

.section-5 .form label {
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .section-5 p {
        margin: 0 25px !important;
    }

    .section-5 .read-demo {
        width: 100% !important;
        margin: 0 !important;
    }

    .section-5 .read-demo>div {
        width: 100% !important;
        margin: 0 0 100px !important;
    }

    .section-5 .read-demo>div button {
        left: 0 !important;
        width: 100% !important;
    }

    .section-5 .container {
        margin: 0 !important;
    }

    .section-5 .container span {
        background-size: cover !important;
        background-position: center !important;
    }

    .section-5 .form {
        padding-bottom: 100px;
    }

    .section-5 .repota {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 1024) {
    .section-5 .repota {
        display: flex;
        flex-direction: row;
    }
}

.pdf-viewer-container {
  margin-bottom: 10px;
  margin-top: 50px;
}

.pdf-viewer-canvas {
  border: 1px solid #ccc;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.controls button {
  margin: 0 5px;
  border: none;
  background-color: hsl(184, 89%, 21%);
  border-radius: 8px;
  color: white;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  max-width: 100px;
}



</style>