<template>
  <Section1 />
  <Section2 />
  <Section3 />
  <Section4 />
  <Section5 />
  <Section6 />
  <Section7 />
  <Section8 />
  <Popup />
</template>

<script>
import Section1 from './components/Section1.vue'
import Section2 from './components/Section2.vue'
import Section3 from './components/Section3.vue'
import Section4 from './components/Section4.vue'
import Section5 from './components/Section5.vue'
import Section6 from './components/Section6.vue'
import Section7 from './components/Section7.vue'
import Section8 from './components/Section8.vue'
import Popup from './components/Popup.vue'

export default {
  name: "App",
  components: { Section1, Section2, Section3, Section4, Section5, Section6, Section7, Section8, Popup },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Nunito:wght@400;700&display=swap');

html,
body {
  overflow-x: hidden;
}

* {
  font-family: 'Noto Sans', sans-serif;
}

.nunito,
.nunito * {
  font-family: 'Nunito', sans-serif;
}

.show {
  animation-name: show;
  animation-duration: 2s;

  @keyframes show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.show-up {
  animation-name: showUp;
  animation-duration: 2s;

  @keyframes showUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.show-down {
  animation-name: showDown;
  animation-duration: 2s;

  @keyframes showDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.show-left {
  animation-name: showLeft;
  animation-duration: 2s;

  @keyframes showLeft {
    from {
      opacity: 0;
      transform: translateX(50px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.show-right {
  animation-name: showRight;
  animation-duration: 2s;

  @keyframes showRight {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.hovering {
  animation-name: hovering;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @keyframes hovering {
    from {
      transform: translateY(0);
    }

    50% {
      transform: translateY(1px);
    }

    to {
      transform: translateY(0);
    }
  }
}

.sine-vertical {
  animation-name: sine-vertical;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @keyframes sine-vertical {
    from {
      transform: translateY(0);
    }

    50% {
      transform: translateY(10px);
    }

    to {
      transform: translateY(0);
    }
  }
}

.sine-horizontal {
  animation-name: sine-horizontal;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @keyframes sine-horizontal {
    from {
      transform: translateX(0);
    }

    50% {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
}

.sine-horizontal-reverse {
  animation-name: sine-horizontal-reverse;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @keyframes sine-horizontal-reverse {
    from {
      transform: translateX(100%);
    }

    50% {
      transform: translateX(0);
    }

    to {
      transform: translateX(100%);
    }
  }
}
</style>