<template>
    <div class="section-4" :style="{ 'color': 'white' }">
        <div class="container-fluid mt-5">
            <div class="row pt-5">
                <div class="col-sm-12">
                    <Title title="DIỄN GIẢ" paddingTitle="15px 50px 5px 30px" :backgroundTitle="img1" fontSizeTitle="56px" fontWeightTitle="bold" />
                </div>
            </div>
            <div :style="[isSmall() ? {'justify-content': 'center', 'display': 'flex', 'flex-direction': 'column', 'margin-top': '80px'} : {'justify-content': 'center', 'display': 'flex', 'flex-direction': 'row', 'margin-top': '80px'}]">
                <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'column', 'align-items': 'center',} : {'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'margin-right': '100px'}]">
                    <img :src="vinh" class="speaker" />
                    <p :style="[isSmall() ? { 'font-size': '24px', 'font-weight': '700', 'color': '#065F66', 'margin-top': '15px' } : { 'font-size': '32px', 'font-weight': '700', 'color': '#065F66', 'margin-top': '15px' }]">Mr. Đặng Phú Vinh</p>
                    <p :style="[isSmall() ? { 'font-size': '16px', 'font-weight': '500', 'color': 'black' } : { 'font-size': '24px', 'font-weight': '500', 'color': 'black' }]">CEO Adsota Agency</p>
                </div>
                <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'column', 'align-items': 'center',} : {'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'margin-right': '100px'}]">
                    <img :src="tinh" class="speaker"/>
                    <p :style="[isSmall() ? { 'font-size': '24px', 'font-weight': '700', 'color': '#065F66', 'margin-top': '15px' } : { 'font-size': '32px', 'font-weight': '700', 'color': '#065F66', 'margin-top': '15px' }]">Mr. Nguyễn Đắc Tình</p>
                    <p :style="[isSmall() ? { 'font-size': '16px', 'font-weight': '500', 'color': 'black' } : { 'font-size': '24px', 'font-weight': '500', 'color': 'black' }]">Co-Founder Ladipage</p>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <img :src="duy" class="speaker"/>
                    <p :style="[isSmall() ? { 'font-size': '24px', 'font-weight': '700', 'color': '#065F66', 'margin-top': '15px' } : { 'font-size': '32px', 'font-weight': '700', 'color': '#065F66', 'margin-top': '15px' }]">Mr. Duy Nguyễn</p>
                    <p :style="[isSmall() ? { 'font-size': '16px', 'font-weight': '500', 'color': 'black' } : { 'font-size': '24px', 'font-weight': '500', 'color': 'black' }]">Partnership Manager tại Zalo Ads</p>
                </div>
                
            </div>
        </div>
        <div class="container-fluid mt-5">
            <div class="row pt-5">
                <div class="col-sm-12">
                    <Title title="QUYỀN LỢI" paddingTitle="15px 50px 5px 30px" :backgroundTitle="img1" fontSizeTitle="56px"
                        fontWeightTitle="bold" />
                    <div class="d-flex justify-content-center mt-3">
                        <p :style="{ 'font-size': '32px', 'color': '#065F66' }">
                            <b>
                                Tham gia sự kiện của chúng tôi, Quý anh/chị có cơ hội:
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center mt-4">
                <Card :img="img2" title="Cập nhật thị trường"
                    content="Thấu hiểu bức tranh tổng quan về thị trường thông qua tài liệu chuyên ngành và báo cáo Repota 2023 " />
                <Card :img="img3" title="Đón đầu giải pháp"
                    content="Nắm bắt các giải pháp, công cụ, framework, template để ứng dụng luôn vào công việc của doanh nghiệp" />
                <Card :img="img4" title="Trải nghiệm độc đáo"
                    content="Trải nghiệm những hoạt động độc đáo xuyên suốt sự kiện và nhận các phần quà đặc biệt từ chương trình" />
                <Card :img="img5" title="Mở rộng networking"
                    content="Giao lưu, kết nối cùng cộng đồng marketing và kinh doanh tại khu vực miền Nam " />
                <Card :img="img6" title="Gặp gỡ chuyên gia"
                    content="Trao đổi, thảo luận và chia sẻ cùng các diễn giả là chuyên gia hàng đầu trong ngành " />
            </div>
        </div>
        <div
            :style="{ 'background-image': 'url(' + bg + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'position': 'relative', 'padding-top': '100px', 'margin-top': '60px' }">
            <div class="container" :style="{ 'font-size': '20px', 'padding': '0 150px 25px' }">
                <div class="row">
                    <Title title="AGENDA" :backgroundTitle="img7" fontSizeTitle="56px" />
                </div>
                <div class="nunito">
                    <div class="row mt-5 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            13:30 - 14:30
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Đón tiếp khách mời
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            14:30 - 14:50
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Giới thiệu chương trình
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            14:50 - 15:35
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Chia sẻ và thảo luận: Tổng quan thị trường 2023 và ứng dụng phương pháp tiếp thị mới.
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            15:35 - 16:10
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Chia sẻ và thảo luận: Các xu hướng mới trên thị trường thương hiệu
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            16:10 - 16:45
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Chia sẻ và thảo luận: Đổi mới thương hiệu bằng phương pháp chuyển đổi số
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            16:45 - 17:25
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Q&A
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            17:25 - 17:45
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Kết thúc chương trình
                        </div>
                    </div>
                </div>
                <div class="row mt-3 pb-3 d-flex justify-content-center mt-3">
                    <button
                        :onclick="scroll"
                        :style="{ 'width': '308px', 'height': '45px', 'border-radius': '8px', 'color': '#065F66', 'font-size': '16px', 'border': 'none' }">
                        <b>
                            ĐĂNG KÝ NGAY
                        </b>
                    </button>
                </div>
            </div>
            <img  :src="img8" alt="" class="d-none d-lg-block img8 fw-bold sine-vertical"
                :style="{ 'position': 'absolute', 'bottom': '-200px', 'right': '0px' }">
        </div>
    </div>
</template>

<script>
import Card from './Card.vue'
import Title from './Title.vue'

export default {
    name: "section-4",
    components: { Card, Title },
    data() {
        return {
            bg: require("@/images/section4/bg.png"),
            img1: require("@/images/section4/1.png"),
            img2: require("@/images/section4/2.png"),
            img3: require("@/images/section4/3.png"),
            img4: require("@/images/section4/4.png"),
            img5: require("@/images/section4/5.png"),
            img6: require("@/images/section4/6.png"),
            img7: require("@/images/section4/7.png"),
            img8: require("@/images/section4/8.png"),
            tinh: require("@/images/section4/tinh.png"),
            duy: require("@/images/section4/duy.png"),
            vinh: require("@/images/section4/vinh.png"),
        }
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            var img8 = this.$el.querySelector('.img8');
            if (img8.getBoundingClientRect().top < window.innerHeight) {
                img8.classList.add('show-up')
            }
        },
        scroll() {
            document.querySelector("#register").scrollIntoView({ behavior: "smooth" });
        },
        isSmall() {
            if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style lang="scss">
.section-4 .line {
    border-bottom: 1px solid white;
}

@media screen and (max-width: 767px) {
    .section-4 {
        span {
            padding: 5px 50px 5px 20px !important;
        }

        .container {
            padding: 0 25px !important;
        }

        .title-image {
            .title {
                font-size: 48px;
            }
        }
    }
}

.speaker {
    max-width: 400px;
    height: auto;
}
</style>