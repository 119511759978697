<template>
    <div class="section-1"
        :style="{ 'background-image': 'url(' + bg + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'padding': '140px 0 200px', 'background-position': 'center',}">
        
        <!-- <img :src="img3" alt="" class="cloud1 show-right">
        <img :src="img4" alt="" class="cloud2 show-right"> -->
        <!-- <img :src="img2" alt="" class="hand show-right"> -->
        <img :src="img5" alt="" class="other show-up">

        <div class="container">
            <div class="col-sm-12 col-lg-5">
                <img :src="adsotalogo"
                    :style="[isSmall() ? {'display': 'block', 'margin': 'auto', 'position': 'relative', 'z-index': 2, 'margin-bottom': '280px', 'margin-top': '-120px', 'width': '220px', 'height': 'auto' } : {'display': 'block', 'margin': 'auto', 'position': 'relative', 'z-index': 2, 'margin-bottom': '100px', 'margin-top': '-120px', 'width': '220px', 'height': 'auto' }]">
                <img class="show-up" :src="img1" alt=""
                    :style="{ 'width': '70%', 'display': 'block', 'margin': 'auto', 'position': 'relative', 'z-index': 2 }">
                <button class="fw-bold hovering"
                    :onclick="scroll"
                    :style="{ 'position': 'relative', 'z-index': 2, 'display': 'block', 'font-size': '16px', 'margin': '50px auto', 'width': '100%', 'max-width': '448px', 'height': '42px', 'border-radius': '8px', 'border': 'none', 'color': 'white', 'background-color': '#065F66' }">
                    Đăng Ký Ngay
                </button>
            </div>
        </div>
        
        <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'column', 'position':'absolute', 'z-index': 2, 'top': '30px', 'width': '100%'} : {'display': 'flex', 'flex-direction': 'row', 'position':'absolute', 'z-index': 2, 'top': '25px', 'left': '42%', 'justify-content': 'space-around'}]">
            <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'column', 'position':'absolute', 'z-index': 2, 'top': '110px', 'left': '60px'} : {'display': 'flex', 'flex-direction': 'column'}]">
            <p :style="[isSmall() ? {'position': 'absolute', 'top': '50%', 'left': '30%', 'margin': '-50px 0 0 -50px'} : {'position': 'absolute', 'top': '30%', 'left': '15%', 'margin': '-50px 0 0 -50px'}]">NHÀ TÀI TRỢ ĐỒNG HÀNH</p>
            <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'row', 'margin-left': '40px'} : {'display': 'flex', 'flex-direction': 'row', 'margin-top': '40px'}]">
                <img :class="[isSmall() ? 'partner-group-sm' : 'partner-group']" :src="group" alt="">
            </div>
            <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'row', 'margin-top': '20px'} : {'display': 'flex', 'flex-direction': 'row', 'margin-top': '40px'}]">
                <img :class="[isSmall() ? 'partner-center-sm' : 'partner-center']" :src="zalo" alt="">
            </div>
        </div>
        <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'column', 'position':'absolute', 'z-index': 2, 'top': '200px', 'left': '60px'} : {'display': 'flex', 'flex-direction': 'column', 'position':'absolute', 'z-index': 2, 'left': '45%'}]">
            <p :style="[isSmall() ? {'position': 'absolute', 'top': '50%', 'left': '30%', 'margin': '-50px 0 0 -50px'} : {'position': 'absolute', 'top': '30%', 'left': '10%', 'margin': '-50px 0 0 -50px'}]">BẢO TRỢ TRUYỀN THÔNG</p>
            <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'row',  'margin-top': '20px', 'margin-left': '40px'} : {'display': 'flex', 'flex-direction': 'row', 'margin-top': '40px'}]">
                <img :class="[isSmall() ? 'partner-sm' : 'partner']" :src="uan" alt="">
                <img :class="[isSmall() ? 'partner-sm' : 'partner']" :src="csmo" alt="">
                <img :class="[isSmall() ? 'partner-sm' : 'partner']" :src="advert" alt="">
            </div>
            <div :style="[isSmall() ? {'display': 'flex', 'flex-direction': 'row', 'margin-left': '40px'} : {'display': 'flex', 'flex-direction': 'row', 'margin-top': '20px'}]">
                <img :class="[isSmall() ? 'partner-sm' : 'partner']" :src="ladipage" alt="">
                <img :class="[isSmall() ? 'partner-sm' : 'partner']" :src="misa" alt="">
                <img :class="[isSmall() ? 'partner-sm' : 'partner']" :src="beat" alt="">
            </div>
        </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "section-1",
    data() {
        return {
            bg: require("@/images/section1/bg.png"),
            adsotalogo: require("@/images/section1/adsotalogo.png"),
            img1: require("@/images/section1/1.png"),
            img3: require("@/images/section1/3.png"),
            img4: require("@/images/section1/4.png"),
            img5: require("@/images/section1/5.png"),
            advert: require("@/images/section1/advert.png"),
            ladipage: require("@/images/section1/ladipage.png"),
            misa: require("@/images/section1/misa.png"),
            csmo: require("@/images/section1/csmo.png"),
            uan: require("@/images/section1/uan.png"),
            beat: require("@/images/section1/beat.png"),
            group: require("@/images/section1/group.png"),
            zalo: require("@/images/section1/zalo.png"),
        }
    },
    methods: {
        scroll() {
            document.querySelector("#register").scrollIntoView({ behavior: "smooth" });
        },
        isSmall() {
            if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style lang="scss">
.section-1 {
    position: relative;

    .hand,
    .cloud1,
    .cloud2,
    .other {
        position: absolute;
    }

    .hand {
        top: 20%;
        left: 0;
        width: 10%;

        @media screen and (max-width: 767px) {
            width: 20%;
        }
    }

    .cloud1 {
        bottom: 0;
        left: 0;
        width: 40%;

        @media screen and (max-width: 767px) {
            width: 80%;
        }
    }

    .cloud2 {
        top: 0;
        left: 0;
        width: 50%;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .other {
        top: 60%;
        left: 20%;
        width: 30%;

        @media screen and (max-width: 767px) {
            width: 80%;
        }
    }

    .partner {
        width: 16%;
        height: 16%;
        margin-right: 10px;
    }

    .partner-sm {
        width: 20%;
        height: auto;
        margin-right: 10px;
        margin-top: 14px;
    }

    .partner-group {
        width: 40%;
        height: auto;
    }

    .partner-group-sm {
        width: 75%;
        height: auto;
    }

    .partner-center {
        width: 12%;
        height: auto;
        align-self: center;
        left: 15%;
        position: absolute;
    }

    .partner-center-sm {
        width: 20%;
        height: auto;
        align-self: center;
        left: 35%;
        position: absolute;
    }

    .vl {
        border-left: 2px solid black;
        height: 115px;
    }
}
</style>