<template>
    <div class="section-6"
        :style="{ 'background-image': 'url(' + bg + ')', 'padding': '100px 0 50px', 'background-size': 'cover', 'background-repeat': 'no-repeat' }">
        <div class="container">
            <div class="row">
                <Title title="KHÁM PHÁ CÁC SỐ REPOTA" subTitle="KHÁC CỦA ADSOTA" :backgroundTitle="img1"
                    fontSizeTitle="36px" fontSizeSubTitle="36px" colorTitle="#065F66" fontWeightTitle="bold"
                    fontWeightSubTitle="bold" />
            </div>
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img2" text="Thị Trường Quảng Cáo Số Việt Nam 2021 - Chiến Lược Đón Đầu Và Đột Phá" @click="open('https://store.appota.com/index.php/s/r4H2cZFp7eLTcQW/download?path=%2F&files=%5BVIE%5D+VN+Digital+MKT+Report+2021_Adsota.pdf')" />
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img3"
                        text="Báo Cáo Khủng Hoảng COVID-19: Tác Động Và Tiềm Năng Phục Hồi (Tháng Năm - 2020)" @click="open('https://www.slideshare.net/AdsotaAds/covid19-crisis-impact-and-recovery')"/>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img4"
                        text="Báo cáo thị trường quảng cáo di động & Tình hình App Monetization tại Việt Nam (Q4-2017)" @click="open('https://www.slideshare.net/AdsotaAds/vietnam-mobile-app-advertising-monetization-report-2017-91368988')"/>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img5" text="Vietnam Digital Advertising Report (H2 - 2019)" @click="open('https://www.slideshare.net/AdsotaAds/vietnam-digital-advertising-report-2019-228704700')"/>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img6" text="Tối ưu chiến lược và công cụ tiếp thị cho tăng trưởng" @click="open('https://drive.google.com/file/d/1V1-d6mMm4CnMmU4ZfoxhhqWMhqtNR3aI/view?usp=sharing')"/>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img7" text="Báo cáo thị trường quảng cáo trực tuyến Việt Nam (Nửa đầu năm 2018)" @click="open('https://www.slideshare.net/AdsotaAds/vietnam-digital-ads-report-h1-2018')"/>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img8" text="Báo cáo thị trường quảng cáo di động & Tình hình App Monetization tại Việt Nam (Q4-2017)" @click="open('https://www.slideshare.net/AdsotaAds/vietnam-mobile-app-advertising-monetization-report-2017-91368988')"/>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <Card2 :img="img9" text="Báo cáo thị trường quảng cáo di động & Tình hình App Monetization tại Việt Nam (Q3-2017)" @click="open('https://www.slideshare.net/AdsotaAds/vietnam-mobile-app-advertising-monetization-report-q32017-83935060')"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Card2 from './Card2.vue'
import Title from './Title.vue'

export default {
    name: 'section-6',
    components: { Card2, Title },
    data() {
        return {
            bg: require("@/images/section6/bg.png"),
            img1: require("@/images/section6/1.png"),
            img2: require("@/images/section6/2.png"),
            img3: require("@/images/section6/3.png"),
            img4: require("@/images/section6/4.png"),
            img5: require("@/images/section6/5.png"),
            img6: require("@/images/section6/6.png"),
            img7: require("@/images/section6/7.png"),
            img8: require("@/images/section6/8.png"),
            img9: require("@/images/section6/9.png"),
        }
    },
    methods: {
        open(url) {
            window.open(url)
        }
    }
}
</script>
<style>
.section-6 span {
    background-size: cover !important;
    background-position: center !important;
}
</style>