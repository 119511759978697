<template>
    <div class="section-3"
        :style="{ 'background-image': 'url(' + bg + ')', 'background-size': 'cover', 'color': 'white', 'background-position': 'center' }">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-3 col-sm-12 pt-5">
                    <div class="pt-5">
                        <Title title="THÔNG TIN" subTitle="SỰ KIỆN" :backgroundSubTitle="img4" fontSizeTitle="56px"
                            fontSizeSubTitle="56px" colorSubTitle="#42A09E" paddingSubTitle="10px 30px 10px 0px"
                            fontWeightTitle="bold" fontWeightSubTitle="bold" />
                    </div>
                </div>
                <div class="col-lg-7 col-sm-12 py-5">
                    <div class="row">
                        <div class="col-sm-12 d-flex my-5">
                            <img :src="img5" alt="" :style="{ 'width': '59px', 'height': '59px', 'margin-top': '10px' }">
                            <div :style="{ 'font-size': '25px', 'margin-left': '30px' }">
                                <div>
                                    <b class="nunito">
                                        Thời gian
                                    </b>
                                </div>
                                <div class="nunito">
                                    13h30 - 17h45 thứ 6 ngày 09.06.2023
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 d-flex my-5">
                            <img :src="img6" alt="" :style="{ 'width': '46px', 'height': '62px', 'margin-top': '10px' }">
                            <div :style="{ 'font-size': '25px', 'margin-left': '30px' }">
                                <div>
                                    <b class="nunito">
                                        Địa điểm
                                    </b>
                                </div>
                                <div class="nunito">
                                    Asiana Plaza, 45 – 47 Phan Đăng Lưu, phường 3, quận Bình Thạnh, TP. HCM
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 d-flex my-5">
                            <img :src="img8" alt="" :style="{ 'width': '58px', 'height': '54px', 'margin-top': '10px' }">
                            <div :style="{ 'font-size': '25px', 'margin-left': '30px' }">
                                <div>
                                    <b class="nunito">
                                        Quy mô
                                    </b>
                                </div>
                                <div class="nunito">
                                    100 người
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col-sm-12 d-flex my-5">
                            <img :src="img7" alt="" :style="{ 'width': '71px', 'height': '53px', 'margin-top': '10px' }">
                            <div :style="{ 'font-size': '25px', 'margin-left': '30px' }">
                                <div>
                                    <b class="nunito">
                                        Khách mời tham dự:
                                    </b>
                                </div>
                                <div class="nunito">
                                    C-Levels, Founder, Manager, các chuyên gia hàng đầu trong ngành Marketing và kinh doanh
                                    tại nhiều lĩnh vực như giáo dục, điện máy, fintech, đồ gia dụng, v.v
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row;">
                            <img :src="cloud" class="sine-horizontal" style="position: relative;"/>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from './Title.vue'
export default {
    name: "section-3",
    components: { Title },
    data() {
        return {
            bg: require("@/images/section3/bg.png"),
            img1: require("@/images/section3/1.png"),
            img2: require("@/images/section3/2.png"),
            img3: require("@/images/section3/3.png"),
            img4: require("@/images/section3/4.png"),
            img5: require("@/images/section3/5.png"),
            img6: require("@/images/section3/6.png"),
            img7: require("@/images/section3/7.png"),
            img8: require("@/images/section3/8.png"),
            cloud: require("@/images/section3/cloud2.png"),
        }
    },
}
</script>

<style>
@media screen and (max-width: 1199px) {
    .section-3 p {
        text-align: center !important;
    }

    .section-3 span {
        display: block !important;
        background-position: center !important;
        text-align: center !important;
    }
}
/* .sine-animation {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    animation: sineAnimation 6s linear infinite;
    }

    @keyframes sineAnimation {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
} */
</style>