<template>
    <div class="title-image">
        <div class="title" v-html="title">
        </div>
        <div class="sub-title" v-html="subTitle">
        </div>
    </div>
</template>
<script>
export default {
    name: 'app-title',
    props: {
        title: {
            type: String,
            default: ''
        },
        fontSizeTitle: {
            type: String,
            default: '24px'
        },
        backgroundTitle: {
            type: String,
            default: ''
        },
        colorTitle: {
            type: String,
            default: 'white'
        },
        paddingTitle: {
            type: String,
            default: '10px'
        },
        fontWeightTitle: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        fontSizeSubTitle: {
            type: String,
            default: '24px'
        },
        backgroundSubTitle: {
            type: String,
            default: ''
        },
        colorSubTitle: {
            type: String,
            default: 'white'
        },
        paddingSubTitle: {
            type: String,
            default: '10px'
        },
        fontWeightSubTitle: {
            type: String,
            default: ''
        },
        position: {
            type: String,
            default: 'center'
        }
    },
    data() {
        return {
            bgTitle: 'url(' + this.backgroundTitle + ')',
            bgSubTitle: 'url(' + this.backgroundSubTitle + ')'
        }
    }
}
</script>
<style lang="scss">
.title-image {
    .title {
        background-image: v-bind(bgTitle);
        font-size: v-bind(fontSizeTitle);
        background-repeat: no-repeat;
        text-align: v-bind(position);
        background-position: v-bind(position);
        color: v-bind(colorTitle);
        padding: v-bind(paddingTitle);
        font-weight: v-bind(fontWeightTitle);
        background-size: auto 100%;
    }

    .sub-title {
        background-image: v-bind(bgSubTitle);
        font-size: v-bind(fontSizeSubTitle);
        background-repeat: no-repeat;
        text-align: v-bind(position);
        background-position: v-bind(position);
        color: v-bind(colorSubTitle);
        padding: v-bind(paddingSubTitle);
        font-weight: v-bind(fontWeightSubTitle);
        background-size: auto 100%;
    }

    @media screen and (max-width: 767px) {
        .title {
            background-size: 100% 100%;
        }

        .sub-title {
            background-size: 100% 100%;
        }
    }
}
</style>