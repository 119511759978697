<template>
    <div class="card-2">
        <img :src="img" alt="">
        <div>{{ text }}</div>
    </div>
</template>
<script>
export default {
    name: 'card-2',
    props: {
        img: String,
        text: String
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            var card2 = this.$el;
            if (card2.getBoundingClientRect().top < window.innerHeight) {
                card2.classList.add('show-up')
            }
        }
    }
}
</script>
<style>
.card-2 {
    margin: 30px 0;
}

.card-2 img {
    width: 100%;
}

.card-2 div {
    width: 100%;
    background-color: white;
    padding: 10px;
    font-size: 14px;
    height: 85px;
}
</style>